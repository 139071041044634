
import axios from "axios";
import { Abstract } from './Abstract'; 
  export class InventoryMaxMin extends Abstract 
  { 
  constructor(session) { 
  super('Inventory/InventoryMaxMin', session) ;

this.id_article = null; 

this.Max = null; 

this.Min = null; 


    }  

    async getCustom() {
        let response = await axios.get(this.controller + "/getCustom", {
            headers: {
                usuario: this.usuario,
                empresa: this.intEmpresa,
                sucursal: this.intSucursal
            }
        });
        return response.data;
      }
 } 
 
