<template>
    <div class="grid crud-demo">
        <Loader v-model="loading" />
        <Helper v-model="InventoryMaxMinHelper" header="Buscar InventoryMaxMin" :headers="headers" :rows="entities" @selected="selectEntity" />
        <div class="col-12">
            <div class="card">
                <Toast />
                <h2>Inventario Maximo-Minimo</h2>
                <BasicFormToolbar @new="openNew" @save="save" @refresh="refresh" @search="InventoryMaxMinHelper.visible = true" @deleted="confirmDelete" :actions="['new', 'refresh', 'delete', 'save', 'search']" />
                <Fieldset :toggleable="true" legend="Informacion">
                    <div class="formgrid grid p-fluid">
                   <FormDropdownComplex
                                            :labelInOption="'barcode - name'"
                                            :labelInValue="'barcode - name'"
                                            :wrapperClass="'field col-12 md:col-4 xl:col-2'"
                                            label="Articulo"
                                            v-model="entity.id_article"
                                            :options="id_articles"
                                            optionLabel="name"
                                            optionValue="id"
                                            :tooltip="'Aparece al llenar Grupo'"
                                        />
                        <FormInputNumber label=" Max " wrapperClass="'field col-12 md:col-4 xl:col-4'" :valid="validate.validations.Max" v-model="entity.max" />

                        <FormInputNumber label=" Min " wrapperClass="'field col-12 md:col-4 xl:col-4'" :valid="validate.validations.Min" v-model="entity.min"  />
                    </div>
                </Fieldset>
                <BasicDatatable :rowedit="true" :rowaction="true" :rowdelete="true" :headers="headers" :rows="entities" @edited="edit" @deleted="confirmDelete" />
                <ConfirmDeleteDialog @closed="deleteDialog = false" v-model="deleteDialog" @deleted="deleteEntity" />
            </div>
        </div>
    </div>
</template>
<script>
import { InventoryMaxMin } from '../../../models/inventarios/InventoryMaxMin';
import { FilterMatchMode } from 'primevue/api';
import { HeaderGrid, Rule, validate, fillObject, Toast, ErrorToast } from '../../../utilities/General';
import Loader from '../../../components/general/Loader.vue';
import Session from '../../../mixins/sessionMixin';
import ConfirmDeleteDialog from '../../../components/general/DeleteDialog.vue';
import BasicFormToolbar from '../../../components/general/BasicFormToolbar.vue';
import BasicDatatable from '../../../components/general/BasicDatatable.vue';
import FormDropdownComplex from '../../../components/general/FormDropdownComplex.vue';
import Helper from '../../../components/general/HelperDialog.vue';
import {InventoryArticle} from '../../../models/inventarios/InventoryArticle';
import FormInputNumber from '../../../components/general/FormInputNumber.vue';



export default {
    mixins: [Session],
    data() {
        return {
            entity: null,
            entities: [],
            id_articles:[],
            // URL para subir el importador**/
            uploadURL: null,
            newDialog: false,
            deleteDialog: false,
            InventoryMaxMinHelper: {
                visible: false,
            },
            filters: {},
            // Aqui agregamos las reglas de validacion, si solo agregamos el nombre sera*/
            // 'required' de tipo 'text'*/
            rules: [new Rule({ name: 'id_article' }), new Rule({ name: 'max' }), new Rule({ name: 'min' })],
            // Aqui agregamos el estatus de la validacion, inicializar en las props 'null'*/
            // y el valid general en false(por que no esta validado aun)*/
            validate: {
                valid: false,
                validations: {
                    id_article: null,
                    max: null,
                    min: null,
                },
            },
            //      Headers donde pondremos el nombre y el valor del datatable*/
            headers: [new HeaderGrid('#Codigo', 'barcode'),new HeaderGrid('Articulo', 'name'), new HeaderGrid('Maximo', 'max'), new HeaderGrid('Minimo', 'min')],
            loading: false,
        };
    },
    components: {
        Loader,
        BasicFormToolbar,
        BasicDatatable,
        ConfirmDeleteDialog,
        Helper,
 FormDropdownComplex,
        FormInputNumber,
    },
    created() {
        console.log(this.session);
        this.entity = new InventoryMaxMin(this.session);
        this.uploadURL = this.$config.api_route + 'Inventory/InventoryMaxMin/Import/' + this.session.empresa + '/' + this.session.sucursal + '/' + this.session.usuario;
        this.initFilters();
    },
    async mounted() {
        await this.refresh();
    },

    methods: {
        openNew() {
            this.entity = new InventoryMaxMin(this.session);
            this.newDialog = true;
        },
        hideDialog() {
            this.newDialog = false;
        },
        async save() {
            try {
                //      * Validacion de form */
                this.loading = true;
                this.validate = validate(this.entity, this.rules);
                if (!this.validate.valid) {
                    throw 'Favor de validar los campos';
                }
                // Si el id es != 0 entonces actualizamos, si no, guardamos*/
                if (this.entity.id && this.entity.id > 0) {
                    // Actualizamos*/
                    let entity = await this.entity.update();
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Actualizar',
                        detail: 'Informacion actualizada con exito',
                        life: 3000,
                    });
                    // Modificamos el listado pah*/
                    let index = this.entities.findIndex((x) => x.id == this.entity.id);
                    this.entities[index] = entity;
                } else {
                    // Creamos uno nuevo*/
                    let entity = await this.entity.save();
                    // Agregamos un dato extra */
                    this.entities.push(entity);
                    this.$toast.add(
                        new Toast({
                            summary: 'Creacion',
                            detail: 'Informacion guardada con exito',
                        })
                    );
                }
                this.entity = new InventoryMaxMin(this.session);
                this.newDialog = false;
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },

        selectEntity(InventoryMaxMin) {
            this.entity = fillObject(this.entity, InventoryMaxMin);
        },
        edit(entity) {
            this.entity = fillObject(this.entity, entity);
            this.newDialog = true;
        },
        confirmDelete(entity) {
            this.entity = fillObject(this.entity, entity);
            this.deleteDialog = true;
        },

        async deleteEntity() {
            try {
                this.loading = true;
                // Eliminamos de la base  */
                await this.entity.delete();
                //* Eliminamos de la vista*/
                this.entities = this.entities.filter((val) => val.id !== this.entity.id);
                this.deleteDialog = false;
                // Limpiamos la entidad */
                this.entity = new InventoryMaxMin(this.session);
                this.$toast.add({
                    severity: 'success',
                    summary: 'Eliminacion',
                    detail: 'Registro eliminado con exito',
                    life: 3000,
                });
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },

        exportCSV() {
            this.$refs.dt.exportCSV();
        },
        initFilters() {
            this.filters = {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            };
        },
        uploadError() {
            this.$toast.add(new ErrorToast('Error al intentar importar archivo'));
        },
        async refresh() {
            this.loading = true;
            try {
                this.entities = await this.entity.getCustom();
                console.log(this.entities)
                this.id_articles=await  new InventoryArticle(this.session).all()
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
    },
};
</script> 
 
<style scoped lang="scss">
</style > 
  
